import foam from './foam.jpg'
import ReactDOM from 'react-dom';
import './App.css'
import {BrowserRouter as Router, Routes, Route, Link, NavLink, isActive, useSearchParams, Navigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import * as Realm from "realm-web";
import Home from './Home';
import Privacy from './Privacy';
import Confirmation from './Confirmation';
import Reset from './Reset';
import EmailUnsubscribe from './EmailUnsubscribe';

function App() {

   const [isLeftAligned, setIsLeftAligned] = useState(true);
   const [bannerVisibility, setBannerVisibility] = useState(false);
   const [navVisibility, setNavVisibility] = useState(true);

  const NavPress = () => {
    setNavVisibility(navVisibility => (!navVisibility));
  };

  return (

    <Router>

      <div className="App">
        <header className="App-header">
          <div
            id="bannerImage"
            className={`${bannerVisibility ? 'fade-out-image' : 'none'}`}
            style={{ backgroundImage: `url(${foam})` }}
          >
            <p
              style={{
                fontSize: 50,
                fontWeight: 600,
                paddingLeft: '6%',
                color: 'white',
                marginTop: '4%'
              }}
            >
              tidepool
            </p>
          </div>


          <div className="text-content">

            <Routes>


            <Route exact path="" element={<Navigate replace to="/home"/>}/>

              <Route
                exact
                path="/home"
                element={<Home setBannerVisibility={setBannerVisibility} bannerVisibility={bannerVisibility} />}
              />

              <Route
                exact
                path="/privacy"
                element={<Privacy
                setBannerVisibility={setBannerVisibility}
                bannerVisibility={bannerVisibility}/> }
              />

              <Route
                exact
                path="/Dev/confirmation"
                element={
                  <Confirmation setBannerVisibility={setBannerVisibility} bannerVisibility={bannerVisibility} stage='Dev' />
                }
              />
              <Route
                exact
                path="/Prod/confirmation"
                element={
                  <Confirmation setBannerVisibility={setBannerVisibility} bannerVisibility={bannerVisibility} stage='Prod' />
                }
              />
              <Route
                exact
                path="/Dev/reset"
                element={<Reset setBannerVisibility={setBannerVisibility} bannerVisibility={bannerVisibility} stage='Dev' />}
                />
              <Route
                exact
                path="/Prod/reset"
                element={<Reset setBannerVisibility={setBannerVisibility} bannerVisibility={bannerVisibility} stage='Prod' />}
              />
              <Route
                exact
                path="/Prod/emailUnsubscribe"
                element={<EmailUnsubscribe setBannerVisibility={setBannerVisibility} bannerVisibility={bannerVisibility} stage='Prod' />}
              />
              <Route
                exact
                path="/Dev/emailUnsubscribe"
                element={<EmailUnsubscribe setBannerVisibility={setBannerVisibility} bannerVisibility={bannerVisibility} stage='Dev' />}
                />
            </Routes>
          </div>
        </header>
      </div>
    </Router>
  );
}

function Nav(props){

  return (
    <nav className =  { props.navVisibility ? "nav-visible fade-in-image" : "nav-invisible fade-out-image" }
                       style = {{position: 'absolute', marginLeft: '53%', width: '50%'}} > 

        <ul style = {{listStyle: 'none', fontSize: 23, fontFamily: 'Lora Regular', marginTop: '7%'}}>

        <li style = {{display: 'inline'}}> 
          <NavLink to = "/home" style = { ({isActive}) => ({ textDecoration: ((isActive) ? 'underline' : 'none'), color: 'black'})}>Home</NavLink> 
        </li>

        <li style = {{display: 'inline', marginLeft: '7%'}}> 
          <NavLink to = "/contact" style = { ({isActive}) => ({ textDecoration: ((isActive) ? 'underline' : 'none'), color: 'black'})}>Contact</NavLink> 
        </li>

      </ul>
    </nav>
  )
}

export default App;