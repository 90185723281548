import React, { useState, useEffect, useRef } from 'react';
import * as Realm from "realm-web";

function EmailUnsubscribe({stage}) {
  // assert that stage is either 'Dev' or 'Prod'
  if (stage !== 'Dev' && stage !== 'Prod') {
    throw new Error('Invalid stage. Must be either "Dev" or "Prod".');
  }
  const [status, setStatus] = useState('loading');
  const [message, setMessage] = useState('');
  const hasAttemptedConfirmation = useRef(false);

  useEffect(() => {
    const confirmEmail = async () => {
      if (hasAttemptedConfirmation.current) return;
      hasAttemptedConfirmation.current = true;

      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get('email');

      if (!email) {
        setStatus('error');
        setMessage('Email parameter not found.');
        return;
      }

      try {
        const REALM_APP_ID = stage === 'Dev' ? process.env.REACT_APP_REALM_APP_ID_DEV : process.env.REACT_APP_REALM_APP_ID_PROD;
        const app = new Realm.App({ id: REALM_APP_ID });
        const user = await app.logIn(Realm.Credentials.anonymous());
        const result = await user.functions.emailUnsubscribe({ 'email': email,
                                                               'subscribed': false });

        console.log('Confirmation result:', result);
        setStatus(result.status);
        setMessage(result.message);
      } catch (error) {
        console.error('Error unsubscribing email:', error);
        setStatus('failure');
        setMessage('There was an error unsubscribing your email. Please try again or contact support: rob@tidepool.fun.');
      } finally {
        // Remove token and tokenId from URL
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    };

    confirmEmail();
  }, []);

  return (
    <div className="confirmation-container">
      {status === 'loading' && <p className="fade-in-text">Unsubscribing your email...</p>}
      {status !== 'loading' && <p className={`fade-in-text ${status}`}>{message}</p>}
    </div>
  );
}

export default EmailUnsubscribe;