import foam from './foam.jpg'
import ReactDOM from 'react-dom';
import './App.css'
import {BrowserRouter as Router, Routes, Route, Link, NavLink, isActive, useSearchParams, Navigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import * as Realm from "realm-web";

export default function Home(props){

    return (
    <>
    <div>
      <p className = 'fade-in-text'>
  
        <b> Tidepool </b> is an app that makes your life easier while you are on vacation.
  
        <br/> <br/>
  
        Didn't have time to research your destination before you arrived? 
        Simply interested in changing things up and finding off-the-beaten track attractions? 
        Check out the homepage for curated suggestions for nearby restaurants, activities, as well as articles 
        about what's what and who's who around town.
  
  
        <br/> <br/>
  
        Rent anything you need or didn't have space to bring along - kayaks, bikes, surfboards - directly in the app. Just browse the inventory, reserve what you need, and kick back - we'll deliver it to you and pick it back up when you're sufficiently sunkissed and windswept.
  
        <br/> <br/>
  
        Our pilot season is happening across a few towns at the Jersey shore this summer -- Ocean City, Longport, Marmora, and Somers Point.
  
        <br/>  <br/>
        Find on the App Store for iOS now.
  
        <br/>  <br/>
        For any questions in the meantime, feel free to shoot us an email at kait@tidepool.fun.
      
        <br/>  <br/>
        See you soon.
  
      </p>
      </div>
      
      </> 
   )
  }
  