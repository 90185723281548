import foam from './foam.jpg'
import ReactDOM from 'react-dom';
import './App.css'
import {BrowserRouter as Router, Routes, Route, Link, NavLink, isActive, useSearchParams, Navigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import * as Realm from "realm-web";

export default function Privacy (props){

    props.setBannerVisibility(true);

    return (

    <>

    <div>
      <p>
        
        <p style= {{fontWeight: 'bold'}}> Privacy Policy </p>

        <p>  This privacy notice for Tidepool LLC ("we," "us," or "our"), describes how and why we might collect, store, use, and/or share ("process") your information when you use our services ("Services"), such as when you: create an account, browse through content on the app, or rent or purchase gear through the app.
        {"\n"}{"\n"}
        Questions or concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services.
        {"\n"} 
        </p>

        <br/>
         
        <p style= {{fontWeight: 'bold'}}> TABLE OF CONTENTS </p>

        <ul>
            <li><a href="#info-collection">1. What information do we collect?</a></li>
            <li><a href="#info-processing">2. How do we process your information?</a></li>
            <li><a href="#info-sharing">3. When and with whom do we share your personal information?</a></li>
            <li><a href="#cookies">4. Do we use cookies and other tracking technologies?</a></li>
            <li><a href="#social-logins">5. How do we handle your social logins?</a></li>
            <li><a href="#info-transfer">6. Is your information transferred internationally?</a></li>
            <li><a href="#info-retention">7. How long do we keep your information?</a></li>
            <li><a href="#info-minors">8. Do we collect information from minors?</a></li>
            <li><a href="#privacy-rights">9. What are your privacy rights?</a></li>
            <li><a href="#do-not-track">10. Controls for do-not-track features</a></li>
            <li><a href="#updates">11. Do we make updates to this notice?</a></li>
            <li><a href="#contact">12. How can you contact us about this notice?</a></li>
            <li><a href="#data-review">13. How can you review, update, or delete the data we collect from you?</a></li>
        </ul>


        <br/> <br/>


        <p style= {{fontWeight: 'bold'}} id="info-collection"> 1. What information do we collect? </p>
        <p> 
                In Short: We collect personal information that you provide to us, along with some automatic information such as device and browser characteristics. {"\n"} {"\n"}

                We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us
                or our products and Services, when you participate in activities on the Services, or otherwise when you contact us. {"\n"} {"\n"}     
                All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.  {"\n"} {"\n"}      
                We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) 
                but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, 
                information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our 
                internal analytics and reporting purposes.  {"\n"} {"\n"}     
                Like many businesses, we also collect information through cookies and similar technologies.
        </p>
       

        <p style= {{fontWeight: 'bold'}} id="info-processing"> 2. How do we process your information?</p>
        <p> 
           In Short: We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. 
           We may also process your information for other purposes with your consent. {"\n"} {"\n"}

            We process your personal information for a variety of reasons, depending on how you interact with our Services, including: to recommend you articles and content, to allow you to 
            complete a rental or purchase transaction, and to allow us to contact you about such transactions. {"\n"} {"\n"}

            When you check out with us, your credit card information is sent and processed via Stripe. We never store it in our own databases.
        </p>


        <p style= {{fontWeight: 'bold'}} id="info-sharing"> 3. When and with whom do we share your personal information?</p>
        <p> 
             In Short: We may share information in specific situations described in this section and/or with the following third parties. {"\n"} {"\n"}
            We may need to share your personal information in the following situations: {"\n"}
            • Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.{"\n"}
            • Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.{"\n"}
            • Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions.
        </p>


        <p style= {{fontWeight: 'bold'}} id="cookies"> 4. Do we use cookies and other tracking technologies?</p>
        <p> 
            In Short: We may use cookies and other tracking technologies to collect and store your information. {"\n"}{"\n"}
            We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information.
        </p>


        <p style= {{fontWeight: 'bold'}} id="social-logins"> 5. How do we handle your social logins?</p>
        <p> 
            In Short: If you choose to register or log in to our Services using a social media account, we may have access to certain information about you. {"\n"}{"\n"}
            Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or Twitter logins). 
            Where you choose to do this, we will receive certain profile information about you from your social media provider. The profile information we receive may vary depending on the social media provider concerned, 
            but will often include your name, email address, friends list, and profile picture, as well as other information you choose to make public on such a social media platform.{"\n"}{"\n"}
            We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services. 
            Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that you 
            review their privacy notice to understand how they collect, use, and share your personal information, and how you can set your privacy preferences on their sites and apps.
        </p>


        <p style= {{fontWeight: 'bold'}} id="info-transfer"> 6. Is your information transferred internationally?</p>
        <p> 
            In Short: We may transfer, store, and process your information in countries other than your own. {"\n"}{"\n"}
            We are a serverless, cloud-based company, meaning that we provision servers all over the United States. In the event that these servers become unavailable, ones abroad may be employed. 
            If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with 
            whom we may share your personal information (see "When and with whom do we share your personal information?" above), in and other countries. {"\n"}{"\n"}
            If you are a resident in the European Economic Area (EEA), United Kingdom (UK), or Switzerland, then these countries may not necessarily have data protection laws 
            or other similar laws as comprehensive as those in your country. However, we will take all necessary measures to protect your personal information in accordance 
            with this privacy notice and applicable law.
        </p>


        <p style= {{fontWeight: 'bold'}} id="info-retention"> 7. How long do we keep your information?</p>
        <p> 
            In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law. {"\n"}{"\n"}
            We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or 
            permitted by law (such as tax, accounting, or other legal requirements). When we have no ongoing legitimate business need to process your personal information, we will either 
            delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely 
            store your personal information and isolate it from any further processing until deletion is possible.
        </p>

        <p style= {{fontWeight: 'bold'}} id="info-minors"> 8. Do we collect information from minors?</p>
        <p> 
            In Short: We do not knowingly collect data from or market to children under 18 years of age. {"\n"}{"\n"}
            We do not knowingly solicit data from or market to children under 18 years of age. 
            By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a 
            minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 
            18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data 
            from our records. If you become aware of any data we may have collected from children under age 18, please contact us at 404 Leenie Ln, Linwood NJ 08221.
        </p>

        <p style= {{fontWeight: 'bold'}} id="privacy-rights"> 9. What are your privacy rights?</p>
        <p> 
            In Short: You may review, change, or terminate your account at any time. {"\n"}{"\n"}
            Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, 
            you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section below. 
            {"\n"}{"\n"}
            However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your 
            personal information conducted in reliance on lawful processing grounds other than consent.
            {"\n"}{"\n"}
            If you would at any time like to review or change the information in your account or terminate your account, you can access these options from the Settings page.
            {"\n"}{"\n"}
            Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information 
            in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
        </p>

        <p style= {{fontWeight: 'bold'}} id="do-not-track"> 10. Controls for do-not-track features</p>
        <p> 
            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("ONT") feature or setting you can activate to signal your privacy preference 
            not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing ONT signals has 
            been finalized. As such, we do not currently respond to ONT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. 
            If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
        </p>


        <p style= {{fontWeight: 'bold'}} id="updates"> 11. Do we make updates to this notice? </p>
        <p> 
            In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws. {"\n"}{"\n"}
            We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as 
            soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. 
            We encourage you to review this privacy notice frequently to be informed of how we are protecting your Information.
        </p>


        <p style= {{fontWeight: 'bold'}} id="contact"> 12. How can you contact us about this notice?</p>
        <p> 
            If you have questions or comments about this notice, you may contact us by post at: 404 Leenie Ln, Linwood NJ 08221.
        </p>


        <p style= {{fontWeight: 'bold'}} id="data-review"> 13. How can you review, update, or delete the data we collect from you?</p>
        <p> 
            Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it.
            To request to review, update, or delete your personal information, please submit a data subject access request. 
            You can contact rob@tidepool.fun with the subject line 'DSAR'.
        </p>

        
      </p>
      </div>
      
      </> 

   )
  }
  