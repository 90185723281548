import './App.css'
import {BrowserRouter as Router, Routes, Route, Link, NavLink, isActive, useSearchParams, Navigate} from 'react-router-dom';
import React, {useState, useEffect} from 'react';
import * as Realm from "realm-web";

export default function Reset(props) {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
  
    const REALM_APP_ID = props.stage === 'Dev' ? process.env.REACT_APP_REALM_APP_ID_DEV : process.env.REACT_APP_REALM_APP_ID_PROD;
    const app = new Realm.App({ id: REALM_APP_ID });
    
    const token = searchParams.get("token");
    const tokenId = searchParams.get("tokenId");
  
    console.log(token, tokenId);
  
    const handlePasswordChange = (e) => {
      setPassword(e.target.value);
    };
  
    const handleConfirmPasswordChange = (e) => {
      setConfirmPassword(e.target.value);
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if (password !== confirmPassword) {
        setError('Passwords do not match!');
      } else {
        setError('');
        // Add the logic to send the password to the backend here
        console.log('Passwords match. Proceed with backend request.');
  
        app.emailPasswordAuth.resetPassword({password, token, tokenId})
      }
    };
  
    useEffect(() => {
      props.setBannerVisibility(false);
    }, [props.bannerVisibility]);
  
    return (
      <>
  
        <div>
          <p className='fade-in-text'
          >
            Please fill out the fields below to reset the password for your Tidepool account.
            <br /> <br />
          </p>
        </div>
  
  
        <form
          onSubmit={handleSubmit}
          className='fade-in-text'
          style={{
            width: '80%'
          }}
        >
  
          <div style = {{marginBottom: '5%'}}>
            <label htmlFor="password">New Password:</label>
            <input
              type="password"
              id="password"
              style = {{marginLeft: '2%', padding: 10, borderRadius: 5, borderWidth: 0.5}}
              value={password}
              onChange={handlePasswordChange}
              required
            />
          </div>
  
          <div style = {{marginBottom: '5%'}}>
            <label htmlFor="confirmPassword">Confirm Password:</label>
            <input
              type="password"
              style = {{marginLeft: '2%', padding: 10, borderRadius: 5, borderWidth: 0.5}}
              id="confirmPassword"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
          </div>
  
          {error && <p style={{ color: 'red' }}>{error}</p>}
  
          <button style = {{backgroundColor:'transparent',
                            borderWidth: 0.5,
                            padding: 10,
                            borderRadius: 5
          }} type="submit">Submit</button>
  
        </form>
      </>
    );
  }